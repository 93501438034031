<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { data, activeCard, SKIN_CAMP } = useScretchCardData();

const buttonName = computed(() =>
	["ready_for_activate", "completed"].includes(activeCard.value?.status) ? t("Jump in") : t("Make Purchase")
);

const handleClick = () => {
	loginGuard({
		success: () => {
			if (activeCard.value?.status === "ready_for_activate" || activeCard.value?.timer) {
				navigateTo("/scratch-cards");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<MPromotionWrapper
		type="scratchCards"
		:title="t(SKIN_CAMP ? 'Scratch Camp' : 'Lucky Scratch')"
		:image="`nuxt-img/${SKIN_CAMP ? 'scratch-cards/camp/promo' : 'promotions/scratch-cards'}.jpg`"
		:buttonName="buttonName"
		buttonInfo
		@click-info="open('LazyOModalScratchCardsHowItWorks')"
		@click-card="handleClick"
	>
		<AText class="description" :size="16" :modifiers="['linght']">
			{{ t("Make purchases to fill the progress:") }}
		</AText>

		<div class="box-progress">
			<MScratchCardsProgress
				:value="activeCard?.progress?.pointsComplete ?? 100"
				:maxValue="activeCard?.progress?.points ?? 100"
			/>
		</div>

		<MCounterBadgeGroup v-if="data?.finishedAt" :timestamp="data?.finishedAt ?? ''" :badgeText="t('Limited Offer')" />
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.box-progress {
	width: 100%;
	margin: 12px 0 8px;
}
:deep(.title) {
	margin-bottom: -12px;
	display: block;
}
</style>
